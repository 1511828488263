import { Cloudinary } from "@cloudinary/url-gen";
import { format } from "@cloudinary/url-gen/actions/delivery";
import { thumbnail } from "@cloudinary/url-gen/actions/resize";
import { face } from "@cloudinary/url-gen/qualifiers/focusOn";
import { png } from "@cloudinary/url-gen/qualifiers/format";
import { focusOn } from "@cloudinary/url-gen/qualifiers/gravity";
import { PixelRatio } from "react-native";

const cld = new Cloudinary({
  cloud: {
    cloudName: "captain-ai",
  },
  url: {
    secure: true,
  },
});

type Options = Partial<{
  size: number;
  pixelRatio: number;
}>;

export function getDriverPictureURL(
  id?: string | null,
  { size = 100, pixelRatio: pixelRatioProp }: Options = {}
) {
  if (!id) return null;

  const pixelRatio = pixelRatioProp ?? PixelRatio.get();

  const image = cld
    .image(id)
    .resize(
      thumbnail()
        .width(size * pixelRatio)
        .height(size * pixelRatio)
        .gravity(focusOn(face()))
    )
    // .roundCorners(byRadius(20))
    .delivery(format(png()));

  return image.toURL();
}
